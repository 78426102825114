import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'
import AboveFooterSection from '../../components/AboveFooterSection'

import Spacing from '../../components/partials/Spacing'
import Layout from '../../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'

import connector_mgt from '../../resources/images/usecases/data-observability/Icons/connector-mgt.svg'
import data_catalog from '../../resources/images/usecases/data-observability/Icons/data-catalog.svg'
import data_exploration from '../../resources/images/usecases/data-observability/Icons/data-exploration.svg'
import data_masking_icon from '../../resources/images/usecases/data-observability/Icons/data-masking.svg'
import flows_monitoring from '../../resources/images/usecases/data-observability/Icons/flows-monitoring.svg'
import metadata_tagging from '../../resources/images/usecases/data-observability/Icons/metadata-tagging.svg'

import document_data from '../../resources/images/usecases/data-observability/document-data.png'
import kafka_commands from '../../resources/images/usecases/data-observability/kafka-commands.png'
import no_visibility from '../../resources/images/usecases/data-observability/no-visibility.png'
import sensitive_data from '../../resources/images/usecases/data-observability/sensitive-data.png'
import topology from '../../resources/images/usecases/data-observability/topology.png'
import understand_data from '../../resources/images/usecases/data-observability/understand-data.png'

import ModalImage from 'react-modal-image'
import { initializeVideos } from '../../helpers/initializeVideo'

const dataObservability = () => {
    const data = useStaticQuery(graphql`
        query DataObservabilitySEO {
            allContentfulPages(
                filter: { pageReference: { pageIdentifier: { eq: "Usecases_Data_Observability" } } }
            ) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    useEffect(() => {
        try {
            initializeVideos()
        } catch (err) {
            console.log(err)
        }
    }, [])

    return (
        <Layout>
            <HeaderSEO pageSEO={pageSEO} />
            <StaticImage
                className="home-fixed-top"
                src="../../resources/images/homepage/hero_lens.svg"
                placeholder="blurred"
                alt="Hero Lenses"
            />
            <section className="hero-margin pb-5">
                <div className="container-1 ipad-margin-top">
                    <div className="d-flex flex-wrap align-items-center">
                        <div className="col-md-6 pt-0">
                            <p className="title breadcrumb-text pt-4">
                                <a href="/usecases/" className="h4-like">
                                    Use cases
                                </a>{' '}
                                <span className="h4-like">/ Data Observability </span>
                            </p>
                            <h1 className="pr-5 pb-4">
                                Apache Kafka observability to avoid flying blind
                            </h1>
                            <div className="pr-5">
                                <p className="hero-description-dark pr-5">
                                    Using a terminal for Kafka observability won’t work. Does
                                    everyone really have to be a Kafka expert to see inside your
                                    event-driven architecture?
                                </p>
                            </div>
                            <div className="roll-button">
                                <a className="bg-red text-white" href="/start/">
                                    <span data-hover="Get started">Get started</span>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6 pt-0">
                            <StaticImage
                                className="img-fluid mx-auto d-block mobile-margin-top-half"
                                src="../../resources/images/usecases/data-observability/hero-image.svg"
                                placeholder="tracedSVG"
                                alt="Data Observability"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <Spacing />

            <section className="bg-cyan mobile-margin-top">
                <div className="container-1 py-5">
                    <div className="text-center">
                        <h2 className="h1-like pb-4 w-90 mx-auto">
                            How many tools does it take to change a lightbulb in Kafka?
                        </h2>{' '}
                        <p className="w-90 mx-auto">
                            Knowing what data you have in Kafka and across your streaming
                            applications is like trying to see in the dark; then there’s the problem
                            of access for the rest of your team.
                        </p>
                        <p className="w-90 mx-auto">
                            The bottom line? Engineers shouldn’t all have to be Kafka know-it-alls
                            to troubleshoot Kafka streams.
                        </p>
                    </div>
                </div>
            </section>

            <Spacing />
            <section>
                <div className="container-1 mobile-margin-top">
                    <div className="d-flex flex-wrap justify-content-center">
                        <div className="col-sm-8 col-10 mb-3">
                            <div className="text-center">
                                <h2 className="h1-like">
                                    What is Kafka observability? Why bother?
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-wrap justify-content-center">
                        <div className="col-md col-sm-6 col-12 mt-1">
                            <div className="border-top-0 rounded-1">
                                <div className="w-100 px-5">
                                    <StaticImage
                                        placeholder="tracedSVG"
                                        src="../../resources/images/usecases/data-observability/faster-troubleshooting.svg"
                                        alt="Faster Kafka troubleshooting"
                                    />
                                </div>
                                <div className="pb-4 px-2 mt-3">
                                    <h3 className="text-center fw-700 cera-pro f-16  lh-29 text-uppercase">
                                        Faster Kafka troubleshooting
                                    </h3>
                                    <p className="pt-3 text-center">of your Kafka streams</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md col-sm-6 col-12 mt-1">
                            <div className="border-top-0 rounded-1">
                                <div className="w-100 px-5">
                                    <StaticImage
                                        src="../../resources/images/usecases/data-observability/team-collaboration.svg"
                                        placeholder="tracedSVG"
                                        alt="Team collaboration"
                                    />
                                </div>
                                <div className="pb-4 px-2 mt-3">
                                    <h3 className="text-center fw-700 cera-pro f-16  lh-29 text-uppercase">
                                        Team collaboration
                                    </h3>
                                    <p className="pt-3 text-center">
                                        A shared understanding of streams through metadata
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md col-sm-6 col-12 mt-1">
                            <div className="border-top-0 rounded-1">
                                <div className="w-100 px-5">
                                    <StaticImage
                                        src="../../resources/images/usecases/data-observability/meet-compliance.svg"
                                        placeholder="tracedSVG"
                                        alt="Meet compliance"
                                    />
                                </div>
                                <div className="pb-4 px-2 mt-3">
                                    <h3 className="text-center fw-700 cera-pro f-16  lh-29 text-uppercase">
                                        Meet compliance
                                    </h3>
                                    <p className="pt-3 text-center">
                                        by discovering sensitive data in your streams
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-wrap justify-content-center">
                        <div className="col-md-4 col-sm-6 col-12 mt-1">
                            <div className="border-top-0 rounded-1">
                                <div className="w-100 px-5">
                                    <StaticImage
                                        src="../../resources/images/usecases/data-observability/accelerated-delivery.svg"
                                        placeholder="tracedSVG"
                                        alt="Accelerated delivery"
                                    />
                                </div>
                                <div className="pb-4 px-2 mt-3">
                                    <h3 className="text-center fw-700 cera-pro f-16  lh-29 text-uppercase">
                                        Accelerated delivery
                                    </h3>
                                    <p className="pt-3 text-center">
                                        of your event-driven applications
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 mt-1">
                            <div className="border-top-0 rounded-1">
                                <div className="w-100 px-5">
                                    <StaticImage
                                        src="../../resources/images/usecases/data-observability/cleaner-data.svg"
                                        placeholder="tracedSVG"
                                        alt="Cleaner Data"
                                    />
                                </div>
                                <div className="pb-4 px-2 mt-3">
                                    <h3 className="text-center fw-700 cera-pro f-16  lh-29 text-uppercase">
                                        Cleaner data
                                    </h3>
                                    <p className="pt-3 text-center">
                                        for fewer incorrect dashboards & upset customers
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Spacing />

            <section className="bg-dark mobile-margin-top">
                <div className="container-1 py-5">
                    <div className="text-center">
                        <div className="w-15 mb-4 mobile-w-50 mx-auto">
                            <StaticImage
                                className="img-fluid"
                                src="../../resources/images/case_studies/article/article_white_logo.svg"
                                placeholder="tracedSVG"
                                alt="Article"
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        <div className="col-sm-9 col-10">
                            <p className="text-white f-20 lh-35 text-center">
                                “We knew that Kafka was critical for our business but we also knew
                                it would be difficult to operate. Using Lenses helps us know where
                                to look for the data we need so we can see what’s working across
                                systems and apps.”
                            </p>
                        </div>
                        <div className="col-sm-8 col-11">
                            <div className="d-flex flex-wrap align-items-center justify-content-center">
                                <StaticImage
                                    src="../../resources/images/case_studies/article/laurent-pernot.png"
                                    quality={100}
                                    alt="Laurent Pernot"
                                    placeholder="tracedSVG"
                                />
                                <p className="f-18 fw-900 text-center text-white ml-3 mobile-text-center mt-3">
                                    {' '}
                                    Laurent Pernot, Software Engineering Manager - Article
                                </p>
                            </div>
                            <div className="text-center mt-4">
                                <a href="/customers/article-wins-marketshare-using-apache-kafka/">
                                    <p className="primary-text f-18 fw-900 text-center pt-2">
                                        How Lenses helped Article break down their monolith{' '}
                                        <i className="red-pointer-right"></i>
                                    </p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Spacing />
            <section>
                <div className="container-1 mobile-margin-top">
                    <div className="d-flex justify-content-center">
                        <div className="col-md-8">
                            <div className="text-center px-2">
                                <h2 className="h1-like">
                                    Best practices for Kafka data observability
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-wrap pt-5 mt-5 align-items-center mobile-margin-reset">
                        <div className="col-md-5 col-11 ">
                            <h2>Engineers struggle with the Kafka commands they need to learn.</h2>
                            <p className="pr-2">
                                Investigate Kafka by exploring events using SQL via a Kafka UI or
                                API. Lenses understands your data irrespective of serialization:
                                Avro, Protobuf, JSON, CSV and more.
                            </p>
                        </div>
                        <div className="offset-md-1"></div>
                        <div className="col-md-6  col-12 ">
                            <ModalImage
                                className="pt-2 w-100"
                                small={kafka_commands}
                                large={kafka_commands}
                                alt="Kafka Commands"
                            />
                        </div>
                    </div>

                    <div className="d-flex flex-wrap flex-column-reverse flex-sm-row py-5 my-5 align-items-center ">
                        <div className="col-md-6  col-12">
                            <ModalImage
                                className="pt-2 w-100"
                                small={no_visibility}
                                large={no_visibility}
                                alt="No visibility"
                            />
                        </div>
                        <div className="offset-md-1 offset-0"></div>
                        <div className="col-md-5 col-12">
                            <h2>We have no visibility into our Kafka topic health.</h2>
                            <p>
                                Explore Kafka topic metrics such as throughput & lag as well as
                                metadata such as partitioning & configuration.
                            </p>
                        </div>
                    </div>

                    <div className="d-flex flex-wrap py-5 my-5 align-items-center ">
                        <div className="col-md-5 col-11">
                            <h2>How can we document what data we have?</h2>
                            <p>
                                Real-time discovery & cataloging of metadata across your Kafka,
                                PostgreSQL and elasticsearch infrastructure.
                            </p>
                        </div>
                        <div className="offset-md-1"></div>
                        <div className="col-md-6  col-12 ">
                            <ModalImage
                                className="pt-2 w-100"
                                small={document_data}
                                large={document_data}
                                alt="Document data"
                            />
                        </div>
                    </div>

                    <div className="d-flex flex-wrap flex-column-reverse flex-sm-row py-5 my-5 align-items-center ">
                        <div className="col-md-6  col-12 ">
                            <ModalImage
                                className="pt-2 w-100"
                                small={understand_data}
                                large={understand_data}
                                alt="Collaborate to Kafka streams"
                            />
                        </div>
                        <div className="offset-md-1"></div>
                        <div className="col-md-5 col-12">
                            <h2>How can we better collaborate & add context to Kafka streams?</h2>
                            <p>
                                Allow engineers to add tags & descriptions to your Kafka and
                                microservice data entities to better find and socialize data.
                            </p>
                        </div>
                    </div>

                    <div className="d-flex flex-wrap my-5 py-5 align-items-center ">
                        <div className="col-md-5 col-11 ">
                            <h2>How can we track data lineage of our data pipelines? </h2>
                            <p>
                                Have a holistic view & data lineage across data stores,
                                microservices and event-driven applications through a UI or
                                Topology.
                            </p>
                        </div>
                        <div className="offset-md-1"></div>
                        <div className="col-md-6  col-12 ">
                            <ModalImage
                                className="pt-2 w-100"
                                small={topology}
                                large={topology}
                                alt="Track data lineage"
                            />
                        </div>
                    </div>

                    <div className="d-flex flex-wrap flex-column-reverse flex-sm-row py-5 mt-5 align-items-center ">
                        <div className="col-md-6  col-12 ">
                            <ModalImage
                                className="pt-2 w-100"
                                small={sensitive_data}
                                large={sensitive_data}
                                alt="Redact sensitive data"
                            />
                        </div>
                        <div className="offset-md-1"></div>
                        <div className="col-md-5 col-12">
                            <h2>How can we redact sensitive data to stay compliant?</h2>
                            <p>
                                Categorize metadata across your applications, connectors and data
                                streams, and then configure pseudonymization and masking to
                                obfuscate fields.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <Spacing />

            <section className="footer-color py-5 mobile-margin-top">
                <div className="text-center">
                    <div className="container-1">
                        <h2 className="h1-like">Kafka observability architecture</h2>
                    </div>
                    <StaticImage
                        className="w-75 mobile-w-100"
                        src="../../resources/images/usecases/data-observability/observability-architecture.svg"
                        placeholder="tracedSVG"
                        alt="Governance & Productivity"
                    />
                </div>
            </section>

            <Spacing />

            <section>
                <div className="container-1 mobile-margin-top">
                    <div className="d-flex flex-wrap justify-content-center">
                        <div className="col-lg-6 col-md-8 col-12">
                            <h2 className="h1-like cera-pro text-center">
                                What are the components of Kafka observability?
                            </h2>
                        </div>
                        <div className="col-sm-11 col-12 mobile-margin-reset">
                            <div className="d-flex flex-wrap">
                                <div className="col-sm mt-5 col-12">
                                    <img
                                        className="usecase-feature"
                                        src={data_exploration}
                                        alt="Data Exploration"
                                    />
                                    <p className="mt-3 text-uppercase cera-pro text-center fw-900 f-20">
                                        Data <br /> Exploration
                                    </p>
                                </div>
                                <div className="col-sm mt-5 col-12">
                                    <img
                                        className="usecase-feature"
                                        src={flows_monitoring}
                                        alt="Flows Monitoring"
                                    />
                                    <p className="mt-3 text-uppercase cera-pro text-center fw-900 f-20">
                                        Flows <br /> monitoring
                                    </p>
                                </div>
                                <div className="col-sm mt-5 col-12">
                                    <img
                                        className="usecase-feature"
                                        src={data_catalog}
                                        alt="Data catalog"
                                    />
                                    <p className="mt-3 text-uppercase cera-pro text-center fw-900 f-20">
                                        Data <br /> catalog
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex flex-wrap">
                                <div className="col-sm mt-5 col-12">
                                    <img
                                        className="usecase-feature"
                                        src={data_masking_icon}
                                        alt="Data masking"
                                    />
                                    <p className="mt-3 text-uppercase cera-pro text-center fw-900 f-20">
                                        Data <br /> masking
                                    </p>
                                </div>

                                <div className="col-sm mt-5 col-12">
                                    <img
                                        className="usecase-feature"
                                        src={metadata_tagging}
                                        alt="Metadata tagging"
                                    />
                                    <p className="mt-3 text-uppercase cera-pro text-center fw-900 f-20">
                                        Metadata <br /> tagging
                                    </p>
                                </div>
                                <div className="col-sm mt-5 col-12">
                                    <img
                                        className="usecase-feature"
                                        src={connector_mgt}
                                        alt="Connector Management"
                                    />
                                    <p className="mt-3 text-uppercase cera-pro text-center fw-900 f-20">
                                        Connection management
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Spacing />

            <section className="bg-usecase-data-observability py-5 mobile-margin-top">
                <div className="container-1">
                    <div className="d-flex flex-wrap justify-content-center">
                        <div className="col-sm-7 col-12">
                            <p className="f-16 text-center cera-pro text-uppercase text-white">
                                {' '}
                                Case Study
                            </p>
                            <div className="text-center pb-4 pt-4">
                                <StaticImage
                                    className="img-fluid w-50"
                                    src="../../resources/images/clients/playtika/playtika_white.png"
                                    placeholder="tracedSVG"
                                    alt="Playtika"
                                />
                            </div>
                            <h2 className="fw-900 text-center text-white h1-like">
                                Gaining 300 Data Engineering Hours Per Day with Lenses
                            </h2>
                        </div>

                        <div className="col-sm-10 col-12">
                            <p className="f-20 text-white text-center mb-0">
                                With over 27 million monthly active users & more than 2.5 thousand
                                coders, learn how this mobile gaming company improved developer
                                productivity through Kafka data observability.
                            </p>

                            <div className="mt-4 text-center">
                                <a className="primary-text" href="/customers/playtika/">
                                    Read more <i className="red-pointer-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Spacing />
            <section>
                <div className="container-1 mobile-margin-top">
                    <div className="d-flex flex-wrap align-items-center">
                        <div className="col-md-5 col-10 pb-0 mobile-padding-left tablet-padding-left">
                            <p className="h4-like mb-1">How to</p>
                            <h2>Navigate data with SQL</h2>
                            <p>
                                Our Snapshot SQL helps you find those needles in a haystack of
                                real-time data. We walk you through how it works.
                            </p>
                            <p>
                                <b>Explore </b>data
                            </p>
                            <p>
                                <b>Debug</b> microservices
                            </p>
                            <p>
                                <b>React</b> to live data
                            </p>
                        </div>
                        <div className="col-md-1 col-12"></div>
                        <div className="col-md-6 col-12">
                            <div className="cloud-aws-msk-video">
                                <iframe
                                    title="Navigating & Querying Apache Kafka with SQL from Lenses.io on Vimeo"
                                    src=""
                                    data-src="//player.vimeo.com/video/443057175"
                                    style={{
                                        position: 'absolute',
                                        top: '0',
                                        left: '0',
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    frameBorder="0"
                                    allow="autoplay; fullscreen"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Spacing />
            <section>
                <div className="container-1 mobile-margin-top">
                    <div className="d-flex flex-wrap">
                        <div className="col-md col-sm-6 col-12 mt-3">
                            <div className="home-three-items-box border-top-0 rounded-1">
                                <StaticImage
                                    className="w-100 rounded-top-1"
                                    src="../../resources/images/usecases/data-observability/blog/data-capture.png"
                                    placeholder="tracedSVG"
                                    alt="Data Capture Monolith Blog"
                                />
                                <div className="py-4 px-3">
                                    <p className="h4-like mb-1">Blog</p>
                                    <div>
                                        <p className="cera-pro">
                                            Change Data Capture and Kafka to break up your monolith
                                        </p>
                                    </div>
                                    <a
                                        className="primary-text home-link-box"
                                        href="/blog/2021/04/change-data-capture-apache-kafka-break-up-monolith/"
                                    >
                                        Read blog <i className="red-pointer-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md col-sm-6 col-12 mt-3">
                            <div className="home-three-items-box border-top-0 rounded-1">
                                <StaticImage
                                    className="w-100 rounded-top-1"
                                    src="../../resources/images/usecases/data-observability/blog/data-catalog.png"
                                    placeholder="tracedSVG"
                                    alt="Data Catalog Blog"
                                />
                                <div className="py-4 px-3">
                                    <p className="h4-like mb-1">Blog</p>
                                    <div>
                                        <p className="cera-pro d-inline">
                                            Data dump to data catalog for{' '}
                                        </p>
                                        <h3 className="f-19 d-inline"> Apache Kafka</h3>{' '}
                                    </div>
                                    <a
                                        className="primary-text home-link-box"
                                        href="/blog/2020/07/data-dump-real-time-data-catalog-apache-kafka/"
                                    >
                                        Read more <i className="red-pointer-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md col-sm-6 col-12 mt-3">
                            <div className="home-three-items-box border-top-0 rounded-1">
                                <StaticImage
                                    className="w-100 rounded-top-1"
                                    src="../../resources/images/usecases/data-observability/blog/distributed-tracing.png"
                                    placeholder="tracedSVG"
                                    alt="Distributed Tracing"
                                />
                                <div className="py-4 px-3">
                                    <p className="h4-like mb-1">Blog</p>
                                    <div>
                                        <p className="cera-pro">
                                            Distributed tracing with Kafka message headers
                                        </p>
                                    </div>
                                    <a
                                        className="primary-text home-link-box"
                                        href="/blog/2020/12/kafka-distributed-tracing-with-message-headers/"
                                    >
                                        Read more <i className="red-pointer-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <AboveFooterSection
                text={'Get your DataOps workspace for Apache Kafka'}
                ctaText={"Let's go"}
                ctaLink="/start/"
            />
        </Layout>
    )
}

export default dataObservability
